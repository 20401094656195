.message {
    display: flex;
    gap: 5px;
    margin-bottom: 15px;

}
.message .messageInfo {
    display: flex;
    flex-direction: column;
    color: gray;
    font-weight: 300;

}
.message .messageInfo img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
}
.message .messageContent {
    width:80%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.message .messageContent p {
    background-color: white;
    padding: 1px 12px;
    max-width: 80%;
    height: auto;
}
.message .messageContent span {
text-align: start;
}
.message.owner .messageContent span {
    text-align: end;
}
.message .messageContent img {
    width: 50%;
}
.message.owner {
    flex-direction: row-reverse;
}
.message.owner .messageContent {
    align-items: flex-end;
}
.message.owner .messageContent p {
    padding: 7px;
    /*#0492c2*/
    /*#1e90ff*/
    /*#0077b6*/
    background-color: #1e90ff;
    color: white;
    border-width: 2px;
    border-radius: 10px 10px 0px 10px;

}
.message.other .messageContent span {
    text-align: end;
}
.message .messageContent img {
    width: 50%;
}
.message.other {
    flex-direction: inherit;
}
.message.other .messageContent {
    align-items: flex-start;
}
.message.other .messageContent p {
    padding: 7px;
    background-color:#D81A71 ;
    color: white;
    border-radius: 10px 10px 10px 0px;
}
.home .container .chat .input {
    border-width: 3px;
    border-color: blue;
    height: 40px;
    background-color: white;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.home .container .chat .input input {
    width: 100%;
    border: none;
    outline: none;
    color: #2f2d52;
    font-size: 18px;
}
.home .container .chat .input input::placeholder {
    color: lightgray;
}
.home .container .chat .input .send {
    display: flex;
    align-items: center;
    gap: 10px;
}
.home .container .chat .input .send .image {
    height: 24px;
    cursor: pointer;
}
.home .container .chat .input .send button {
    border: none;
    padding: 10px 15px;
    color: white;
    background-color: #8da4f1;
    cursor: pointer;
}
.input {
    height: 70px;
    background-color: white;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.input input {
    width: 100%;
    border: none;
    outline: none;
    color: #2f2d52;
    font-size: 18px;
}
.input input::placeholder {
    color: lightgray;
}
.input .send {
    display: flex;
    align-items: center;
    gap: 10px;
}
.input .send button {
    border: none;
    padding: 10px 15px;
    color: white;
    background-color: #8da4f1;
    cursor: pointer;
}
.chatPages {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.menu-scrollable::-webkit-scrollbar {
    width: 5px;
}

.menu-scrollable::-webkit-scrollbar-track {
    background-color: #F5F5F5;
}

.menu-scrollable::-webkit-scrollbar-thumb {
    background-color: darkred;
    border-radius: 20px;
}

.menu-scrollable::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

@font-face {
    font-family: 'Hanley Pro Script Alt';
    src: url('/public/HanleyPro-Monoline.ttf') format('truetype');
}
@keyframes loadingAnimation {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

.letter {
    font-size: 24px;
    /*color: #D81E71;*/
    font-family: 'Hanley Pro Script Alt', cursive;
    animation: loadingAnimation 1s linear infinite;
}

.animate {
    animation-name: loadingAnimation;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}